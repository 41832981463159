<template>
  <div>
    {{ output }}
  </div>
</template>

<script>
  export default {

    name: "Test",
    data(){
      return{
        output: '', // 初始化输出内容为空
      }
    },
    created() {
      this.fetchData();
    },
    methods:{
      fetchData() {
        let msg={
          role: 'user',
          content: '写一个200字日记',
          timestamp: Date.now()
        };
        this.$store.state.messages.push(msg);

        fetch('http://localhost:8088/ai/stream', {
          method: 'POST',
          body: JSON.stringify(this.$store.state.messages),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            const reader = response.body.getReader();
            // 逐步接收流数据
            const read = () => {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  return;
                }
                // 将收到的数据转为文本
                const text = new TextDecoder('utf-8').decode(value);
                console.log(text)

                this.output+=text;
                // 继续读取下一块数据
                return read();
              });
            };

            // 开始读取数据
            return read();
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }
    }
  }
</script>

<style scoped>

</style>